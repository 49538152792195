import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { useUserData } from "@/hooks/useUserData";
import { SendHorizontal } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

interface Industry {
  name: string;
}

interface BusinessRequirement {
  id: number;
  title: string;
  description: string;
  status: string;
  created_at: string;
  industries: Industry[];
  user: {
    name: string;
    company_name: string;
  };
}

export const ExploreRequirements = () => {
  const [requirements, setRequirements] = useState<BusinessRequirement[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedIndustry, setSelectedIndustry] = useState<string | null>(null);
  const [industries, setIndustries] = useState<{ id: number; name: string; }[]>([]);
  const { currentUserId } = useUserData();
  const { toast } = useToast();

  useEffect(() => {
    fetchIndustries();
    fetchRequirements();
  }, [selectedIndustry, currentUserId]);

  const fetchIndustries = async () => {
    const { data, error } = await supabase
      .from('industries')
      .select('*')
      .order('name');
    
    if (error) {
      console.error('Error fetching industries:', error);
      return;
    }

    setIndustries(data || []);
  };

  const fetchRequirements = async () => {
    try {
      let query = supabase
        .from('business_requirements')
        .select(`
          *,
          business_requirement_industries (
            industries (
              name
            )
          ),
          user:users (
            name,
            company_name
          )
        `)
        .eq('status', 'active')
        .neq('user_id', currentUserId)
        .order('created_at', { ascending: false });

      if (selectedIndustry && selectedIndustry !== 'all') {
        const { data: requirementIds } = await supabase
          .from('business_requirement_industries')
          .select('requirement_id')
          .eq('industry_id', parseInt(selectedIndustry));

        if (requirementIds) {
          query = query.in('id', requirementIds.map(r => r.requirement_id));
        }
      }

      const { data, error } = await query;

      if (error) throw error;

      const transformedData = (data || []).map(req => ({
        ...req,
        industries: req.business_requirement_industries.map(bri => ({
          name: bri.industries.name
        }))
      }));

      setRequirements(transformedData);
    } catch (error) {
      console.error('Error fetching requirements:', error);
      toast({
        title: "Error",
        description: "Failed to fetch business requirements",
        variant: "destructive"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitProposal = async (requirementId: number) => {
    // This will be implemented in the next step
    console.log('Submit proposal for requirement:', requirementId);
  };

  if (loading) {
    return <div className="text-center text-muted-foreground py-4">Loading requirements...</div>;
  }

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="industry-filter">Filter by Industry</Label>
        <Select
          value={selectedIndustry || undefined}
          onValueChange={setSelectedIndustry}
        >
          <SelectTrigger id="industry-filter">
            <SelectValue placeholder="All Industries" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Industries</SelectItem>
            {industries.map((industry) => (
              <SelectItem key={industry.id} value={industry.id.toString()}>
                {industry.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {requirements.length === 0 ? (
        <Card>
          <CardContent className="py-8">
            <p className="text-center text-muted-foreground">
              No business requirements found
            </p>
          </CardContent>
        </Card>
      ) : (
        requirements.map((requirement) => (
          <Card key={requirement.id}>
            <CardHeader>
              <div className="flex justify-between items-start">
                <div>
                  <CardTitle>{requirement.title}</CardTitle>
                  <p className="text-sm text-muted-foreground mt-1">
                    Posted by {requirement.user.name} from {requirement.user.company_name}
                  </p>
                </div>
                <Button
                  variant="outline"
                  className="ml-4"
                  onClick={() => handleSubmitProposal(requirement.id)}
                >
                  <SendHorizontal className="h-4 w-4 mr-2" />
                  Submit Proposal
                </Button>
              </div>
              <div className="flex gap-2 mt-2">
                {requirement.industries?.map((industry, index) => (
                  <Badge key={index} variant="secondary">
                    {industry.name}
                  </Badge>
                ))}
              </div>
            </CardHeader>
            <CardContent>
              <p className="text-gray-600">{requirement.description}</p>
            </CardContent>
          </Card>
        ))
      )}
    </div>
  );
};