import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { useUserData } from "@/hooks/useUserData";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { MessageSquare } from "lucide-react";

interface Industry {
  name: string;
}

interface BusinessProposal {
  id: number;
  content: string;
  created_at: string;
  user: {
    name: string;
    company_name: string;
  };
}

interface BusinessRequirement {
  id: number;
  title: string;
  description: string;
  status: string;
  created_at: string;
  industries: Industry[];
  proposals: BusinessProposal[];
}

export const MyRequirements = () => {
  const [requirements, setRequirements] = useState<BusinessRequirement[]>([]);
  const [loading, setLoading] = useState(true);
  const { currentUserId } = useUserData();
  const { toast } = useToast();

  useEffect(() => {
    if (currentUserId) {
      fetchMyRequirements();
    }
  }, [currentUserId]);

  const fetchMyRequirements = async () => {
    try {
      const { data, error } = await supabase
        .from('business_requirements')
        .select(`
          *,
          business_requirement_industries (
            industries (
              name
            )
          ),
          proposals:business_proposals (
            id,
            content,
            created_at,
            user:users (
              name,
              company_name
            )
          )
        `)
        .eq('user_id', currentUserId)
        .order('created_at', { ascending: false });

      if (error) throw error;

      const transformedData = (data || []).map(req => ({
        ...req,
        industries: req.business_requirement_industries.map(bri => ({
          name: bri.industries.name
        })),
        proposals: req.proposals || []
      }));

      setRequirements(transformedData);
    } catch (error) {
      console.error('Error fetching requirements:', error);
      toast({
        title: "Error",
        description: "Failed to fetch your business requirements",
        variant: "destructive"
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="text-center text-muted-foreground py-4">Loading your requirements...</div>;
  }

  if (requirements.length === 0) {
    return (
      <Card>
        <CardContent className="py-8">
          <p className="text-center text-muted-foreground">
            You haven't posted any business requirements yet
          </p>
        </CardContent>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      {requirements.map((requirement) => (
        <Card key={requirement.id}>
          <CardHeader>
            <CardTitle>{requirement.title}</CardTitle>
            <div className="flex gap-2 mt-2">
              {requirement.industries?.map((industry, index) => (
                <Badge key={index} variant="secondary">
                  {industry.name}
                </Badge>
              ))}
            </div>
          </CardHeader>
          <CardContent className="space-y-4">
            <p className="text-gray-600">{requirement.description}</p>
            
            <div className="mt-6">
              <h4 className="text-lg font-semibold flex items-center gap-2 mb-4">
                <MessageSquare className="h-5 w-5" />
                Proposals ({requirement.proposals.length})
              </h4>
              
              {requirement.proposals.length === 0 ? (
                <p className="text-muted-foreground text-sm">
                  No proposals received yet
                </p>
              ) : (
                <div className="space-y-4">
                  {requirement.proposals.map((proposal) => (
                    <Card key={proposal.id}>
                      <CardContent className="py-4">
                        <div className="flex justify-between items-start mb-2">
                          <div>
                            <p className="font-medium">{proposal.user.name}</p>
                            <p className="text-sm text-muted-foreground">
                              {proposal.user.company_name}
                            </p>
                          </div>
                          <Button variant="outline" size="sm">
                            Contact
                          </Button>
                        </div>
                        <p className="text-sm mt-2">{proposal.content}</p>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};