import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useGoogleAnalytics } from "@/hooks/useGoogleAnalytics";
import Navigation from "@/components/Navigation";
import AudioPlayer from "@/components/AudioPlayer";
import Index from "./pages/Index";
import Landing from "./pages/Landing";
import LiveEvents from "./pages/LiveEvents";
import PartnerWithUs from "./pages/PartnerWithUs";
import Contact from "./pages/Contact";
import EventRegistration from "./pages/EventRegistration";
import Dashboard from "./pages/Dashboard";
import StartupBrewhouseEp1 from "./pages/StartupBrewhouseEp1";
import Profile from "./pages/Profile";
import PublicProfile from "./pages/PublicProfile";
import Settings from "./pages/Settings";
import MySpace from "./pages/MySpace";
import Install from "./pages/Install";
import StartupPartner from "./pages/partners/StartupPartner";
import VCPartner from "./pages/partners/VCPartner";
import ArtistPartner from "./pages/partners/ArtistPartner";
import BloggerPartner from "./pages/partners/BloggerPartner";
import AcceleratorPartner from "./pages/partners/AcceleratorPartner";
import IncubatorPartner from "./pages/partners/IncubatorPartner";

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <TooltipProvider>
          <AppRoutes />
          <AudioPlayer />
          <Toaster />
          <Sonner />
        </TooltipProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

const AppRoutes = () => {
  useGoogleAnalytics();
  
  return (
    <>
      <Navigation />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/business" element={<Index />} />
        <Route path="/home" element={<Navigate to="/business" replace />} />
        <Route path="/landing" element={<Navigate to="/" replace />} />
        <Route path="/live-events" element={<LiveEvents />} />
        <Route path="/partner/startup" element={<StartupPartner />} />
        <Route path="/partner/vc" element={<VCPartner />} />
        <Route path="/partner/artist" element={<ArtistPartner />} />
        <Route path="/partner/blogger" element={<BloggerPartner />} />
        <Route path="/partner/accelerator" element={<AcceleratorPartner />} />
        <Route path="/partner/incubator" element={<IncubatorPartner />} />
        <Route path="/creator" element={<Navigate to="/partner/artist" replace />} />
        <Route path="/marketer" element={<Navigate to="/partner/blogger" replace />} />
        <Route path="/communities" element={<Navigate to="/partner" replace />} />
        <Route path="/partner" element={<PartnerWithUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/register" element={<EventRegistration />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/the-startup-brewhouse-ep1" element={<StartupBrewhouseEp1 />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/:userId" element={<PublicProfile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/my-space" element={<MySpace />} />
        <Route path="/install" element={<Install />} />
      </Routes>
    </>
  );
};

export default App;