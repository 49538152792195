import { Coffee, Users, Radio, Podcast, Megaphone, Handshake, ArrowRight } from "lucide-react";
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";
import Events from "@/components/sections/Events";
import Partners from "@/components/sections/Partners";

const Landing = () => {
  return (
    <div className="min-h-screen bg-hover-cream">
      {/* Hero Section */}
      <section className="pt-24 pb-16 px-4">
        <div className="container mx-auto max-w-6xl">
          <div className="text-center space-y-6 animate-fade-in">
            <img 
              src="/lovable-uploads/384cd05f-f725-4ae0-a8a9-b903d2075f8c.png" 
              alt="HoverBean"
              className="mx-auto w-96 mb-8"
            />
            <div className="text-4xl md:text-6xl font-bold text-hover-espresso">
              <Typewriter
                options={{
                  strings: ['Build Communities', 'Network Better', 'Engage & Earn'],
                  autoStart: true,
                  loop: true,
                  deleteSpeed: 50,
                  delay: 80,
                }}
              />
            </div>
          </div>
        </div>
      </section>

      {/* Partners Section */}
      <Partners />

      {/* About Section */}
      <section className="py-12 px-4 bg-white/50">
        <div className="container mx-auto max-w-6xl">
          <div className="text-center">
            <h2 className="text-3xl md:text-4xl font-bold text-hover-espresso mb-4">
              Brew Your Community's Success
            </h2>
            <p className="text-hover-coffee text-lg max-w-3xl mx-auto leading-relaxed">
              HoverBean is your platform for transforming creator & business communities. 
              Elevate engagement, foster connections, and amplify your reach through 
              personalized radio streams and collaborative content creation.
            </p>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-16 px-4 bg-white/50">
        <div className="container mx-auto max-w-6xl">
          <div className="grid md:grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <motion.div 
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="p-8 rounded-2xl bg-white shadow-sm border border-hover-coffee/10"
              >
                <div className="w-12 h-12 rounded-xl bg-hover-coffee/10 flex items-center justify-center mb-6">
                  {feature.icon}
                </div>
                <h3 className="text-2xl font-semibold text-hover-espresso mb-4">
                  {feature.title}
                </h3>
                <p className="text-hover-coffee leading-relaxed">
                  {feature.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Value Exchange Section */}
      <section className="py-16 px-4">
        <div className="container mx-auto max-w-6xl">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-hover-foam rounded-2xl p-8 md:p-12 shadow-sm border border-hover-coffee/10"
          >
            <div className="flex flex-col md:flex-row items-center gap-8">
              <div className="flex-1">
                <h2 className="text-3xl font-bold text-hover-espresso mb-6">
                  Grow Through Value Exchange
                </h2>
                <p className="text-hover-coffee text-lg mb-6">
                  The more value you add to peers who need your expertise, the more 
                  we connect you with individuals who can elevate your ventures.
                </p>
                <div className="flex items-center gap-4 text-hover-coffee">
                  <Handshake className="w-6 h-6" />
                  <p className="font-medium">
                    Match with the right people to scale your ideas
                  </p>
                </div>
              </div>
              <div className="flex-1">
                <div className="bg-hover-cream rounded-xl p-6">
                  <h3 className="text-xl font-semibold text-hover-espresso mb-4">
                    How It Works
                  </h3>
                  <ul className="space-y-4">
                    <li className="flex items-start gap-3">
                      <ArrowRight className="w-5 h-5 text-hover-coffee mt-1 flex-shrink-0" />
                      <p>Share your expertise and help others in your field</p>
                    </li>
                    <li className="flex items-start gap-3">
                      <ArrowRight className="w-5 h-5 text-hover-coffee mt-1 flex-shrink-0" />
                      <p>Build reputation through meaningful contributions</p>
                    </li>
                    <li className="flex items-start gap-3">
                      <ArrowRight className="w-5 h-5 text-hover-coffee mt-1 flex-shrink-0" />
                      <p>Get matched with partners who complement your goals</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Engage In-Person Section */}
      <section className="py-16 px-4">
        <div className="container mx-auto max-w-6xl">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-hover-foam rounded-2xl p-8 md:p-12 shadow-sm border border-hover-coffee/10"
          >
            <h2 className="text-3xl font-bold text-hover-espresso mb-6 text-center">
              Engage In-Person!
            </h2>
            <p className="text-hover-coffee text-lg text-center mb-8 max-w-3xl mx-auto">
              Engage with people of interest at our partner locations using the points (Coffee Beans) 
              you earn with us. Check out some of the events we have on-going.
            </p>
            
            {/* Events Section */}
            <Events />
          </motion.div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 px-4">
        <div className="container mx-auto max-w-6xl text-center">
          <div className="bg-white/50 rounded-2xl p-12 border border-hover-coffee/10">
            <h2 className="text-3xl md:text-4xl font-bold text-hover-espresso mb-6">
              Ready to Brew Something Special?
            </h2>
            <p className="text-hover-coffee text-lg mb-8 max-w-2xl mx-auto">
              Join HoverBean today and transform your community into a thriving ecosystem 
              of engaged creators and passionate audiences.
            </p>
            <a 
              href="/partner"
              className="inline-flex items-center px-6 py-3 rounded-lg bg-hover-coffee text-white hover:bg-hover-espresso transition-colors"
            >
              <Coffee className="w-5 h-5 mr-2" />
              Partner With Us
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

const features = [
  {
    icon: <Radio className="w-6 h-6 text-hover-coffee" />,
    title: "Your Community Radio",
    description: "Bring your community to us, and we'll set up your exclusive radio stream. We provide the music and initial content boost to get you started, completely on us."
  },
  {
    icon: <Users className="w-6 h-6 text-hover-coffee" />,
    title: "Moderated Growth",
    description: "We collaborate with selected moderators from your community to facilitate meaningful connections with partner networks, ensuring value-driven growth for all participants."
  },
  {
    icon: <Podcast className="w-6 h-6 text-hover-coffee" />,
    title: "Niche Content Creation",
    description: "Generate highly targeted content including podcasts, talk shows, music, and comedy, driven by your sub-communities' interests and expertise."
  },
  {
    icon: <Megaphone className="w-6 h-6 text-hover-coffee" />,
    title: "Cross-Platform PR Boost",
    description: "Amplify your presence with our comprehensive PR support across our platform and your social media channels, maximizing your community's visibility and impact."
  }
];

export default Landing;