import { Rocket, Building2, Mic2, Pencil, Lightbulb, Coffee } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";

const partnerIcons = {
  "Startups": Rocket,
  "VCs": Building2,
  "Artists": Mic2,
  "Content Creators": Pencil,
  "Accelerators": Lightbulb,
  "Incubators": Coffee
};

const backgroundPatterns = [
  `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M30,50 Q50,30 70,50 T90,50' stroke='rgba(255,255,255,0.1)' fill='none'/%3E%3Ccircle cx='50' cy='50' r='5' fill='rgba(255,255,255,0.1)'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20,40 Q40,20 60,40 T100,40' stroke='rgba(255,255,255,0.1)' fill='none'/%3E%3Ccircle cx='40' cy='40' r='4' fill='rgba(255,255,255,0.1)'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10,50 Q30,30 50,50 T90,50' stroke='rgba(255,255,255,0.1)' fill='none'/%3E%3Ccircle cx='30' cy='50' r='6' fill='rgba(255,255,255,0.1)'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25,60 Q45,40 65,60 T85,60' stroke='rgba(255,255,255,0.1)' fill='none'/%3E%3Ccircle cx='45' cy='60' r='3' fill='rgba(255,255,255,0.1)'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15,45 Q35,25 55,45 T95,45' stroke='rgba(255,255,255,0.1)' fill='none'/%3E%3Ccircle cx='35' cy='45' r='5' fill='rgba(255,255,255,0.1)'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20,55 Q40,35 60,55 T80,55' stroke='rgba(255,255,255,0.1)' fill='none'/%3E%3Ccircle cx='40' cy='55' r='4' fill='rgba(255,255,255,0.1)'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M30,35 Q50,15 70,35 T90,35' stroke='rgba(255,255,255,0.1)' fill='none'/%3E%3Ccircle cx='50' cy='35' r='6' fill='rgba(255,255,255,0.1)'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25,65 Q45,45 65,65 T85,65' stroke='rgba(255,255,255,0.1)' fill='none'/%3E%3Ccircle cx='45' cy='65' r='3' fill='rgba(255,255,255,0.1)'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10,40 Q30,20 50,40 T70,40' stroke='rgba(255,255,255,0.1)' fill='none'/%3E%3Ccircle cx='30' cy='40' r='5' fill='rgba(255,255,255,0.1)'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15,60 Q35,40 55,60 T75,60' stroke='rgba(255,255,255,0.1)' fill='none'/%3E%3Ccircle cx='35' cy='60' r='4' fill='rgba(255,255,255,0.1)'/%3E%3C/svg%3E")`
];

const unsplashQueries = {
  "Startups": "startup-office-modern",
  "VCs": "venture-capital-modern-office",
  "Artists": "artist-studio-creative",
  "Content Creators": "content-creator-workspace",
  "Accelerators": "tech-accelerator-space",
  "Incubators": "modern-coworking-space"
};

const Partners = () => {
  const { data: groups, isLoading } = useQuery({
    queryKey: ['groups'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('groups')
        .select('*')
        .limit(6);
      
      if (error) throw error;
      return data;
    }
  });

  if (isLoading) {
    return <div className="mt-32 text-center">Loading...</div>;
  }

  return (
    <section className="relative mt-32 space-y-12 px-4 md:px-8 lg:px-12 overflow-visible z-0">
      <div className="text-center space-y-4">
        <h2 className="text-4xl font-bold text-hover-espresso">
          Partner With Us!
        </h2>
        <p className="text-hover-coffee text-lg max-w-2xl mx-auto">
          We empower communities and bring their vision to a larger audience!
        </p>
      </div>

      <div className="relative max-w-7xl mx-auto overflow-visible">
        <Carousel
          opts={{
            align: "start",
            loop: true,
          }}
          className="relative w-full overflow-visible"
        >
          <CarouselContent className="-ml-2 md:-ml-4 overflow-visible">
            {groups?.map((group, index) => {
              const Icon = partnerIcons[group.name as keyof typeof partnerIcons] || Coffee;
              const query = unsplashQueries[group.name as keyof typeof unsplashQueries] || "modern-office";
              return (
                <CarouselItem key={group.id} className="relative pl-2 md:pl-4 md:basis-1/2 lg:basis-1/3 overflow-visible">
                  <div
                    className="group relative p-6 rounded-2xl overflow-hidden transition-all duration-500 hover:-translate-y-2 hover:shadow-xl h-full"
                    style={{
                      background: '#4A3023',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                      zIndex: 50,
                      transform: 'translate3d(0, 0, 0)',
                      backgroundImage: backgroundPatterns[index % backgroundPatterns.length],
                      backgroundSize: '200px 200px',
                      backgroundRepeat: 'repeat'
                    }}
                  >
                    <div className="absolute inset-0">
                      <div className="absolute inset-0 bg-gradient-to-br from-pink-500/60 to-rose-500/60 opacity-70 group-hover:opacity-80 transition-opacity" />
                      <div className="absolute inset-0" style={{ 
                        backgroundImage: backgroundPatterns[index % backgroundPatterns.length],
                        backgroundSize: '200px 200px',
                        backgroundRepeat: 'repeat',
                        opacity: 0.15
                      }} />
                    </div>
                    <div className="relative z-20 space-y-4">
                      <div className="w-12 h-12 rounded-xl bg-white/90 backdrop-blur-sm flex items-center justify-center shadow-lg">
                        <Icon className="w-6 h-6 text-hover-coffee" />
                      </div>
                      <h3 className="text-xl font-semibold text-white">
                        {group.name}
                      </h3>
                      <p className="text-white">
                        {group.description || `Join our ${group.name} community`}
                      </p>
                      <Button
                        variant="outline"
                        className="w-full bg-white/20 border-white/30 text-white hover:bg-white hover:text-hover-coffee transition-all duration-300"
                        asChild
                      >
                        <Link to={`/groups/${group.id}`}>Join Now</Link>
                      </Button>
                    </div>
                  </div>
                </CarouselItem>
              );
            })}
          </CarouselContent>
          <CarouselPrevious className="hidden md:flex" />
          <CarouselNext className="hidden md:flex" />
        </Carousel>
      </div>
    </section>
  );
};

export default Partners;