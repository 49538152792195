import { useState } from "react";
import { Button } from "@/components/ui/button";
import { BusinessRequirementForm } from "./BusinessRequirementForm";
import { ExploreRequirements } from "./ExploreRequirements";
import { MyRequirements } from "./MyRequirements";
import { Plus, X } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

export const BusinessSection = () => {
  const [showForm, setShowForm] = useState(false);

  return (
    <div className="space-y-6">
      {!showForm ? (
        <Button
          onClick={() => setShowForm(true)}
          className="w-full bg-hover-coffee text-white hover:bg-hover-espresso shadow-md hover:shadow-lg transition-all duration-300 group"
        >
          <Plus className="h-5 w-5 mr-2 group-hover:rotate-90 transition-transform duration-300" />
          Post Business Requirement
        </Button>
      ) : (
        <div className="relative bg-white rounded-xl shadow-lg border-2 border-hover-coffee/10 p-6 animate-fade-in">
          <Button
            variant="ghost"
            size="icon"
            className="absolute right-4 top-4 text-muted-foreground hover:text-hover-coffee"
            onClick={() => setShowForm(false)}
          >
            <X className="h-5 w-5" />
          </Button>
          <BusinessRequirementForm onSuccess={() => setShowForm(false)} />
        </div>
      )}

      <Tabs defaultValue="explore" className="w-full">
        <TabsList className="w-full">
          <TabsTrigger value="explore" className="flex-1">Explore Business Requirements</TabsTrigger>
          <TabsTrigger value="my-requirements" className="flex-1">My Business Requirements</TabsTrigger>
        </TabsList>
        <TabsContent value="explore">
          <ExploreRequirements />
        </TabsContent>
        <TabsContent value="my-requirements">
          <MyRequirements />
        </TabsContent>
      </Tabs>
    </div>
  );
};