import { useState, useRef, useEffect } from "react";
import { Volume2, VolumeX, Play, Pause, Radio } from "lucide-react";
import { useIsMobile } from "@/hooks/use-mobile";

const AudioPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const audioRef = useRef<HTMLAudioElement>(null);
  const isMobile = useIsMobile();
  const streamUrl = "https://phoebe.streamerr.co:3150/;?type=http&nocache=1738419731";

  useEffect(() => {
    if (audioRef.current) {
      // Set initial volume
      audioRef.current.volume = volume;
      audioRef.current.src = streamUrl;
      
      // Add error handling
      audioRef.current.onerror = (e) => {
        console.error("Audio error:", e);
        setIsLoading(false);
      };
    }

    // Cleanup
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = "";
      }
    };
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  const togglePlay = async () => {
    if (audioRef.current) {
      try {
        if (isPlaying) {
          audioRef.current.pause();
          console.log("Pausing audio");
        } else {
          setIsLoading(true);
          console.log("Attempting to play audio");
          await audioRef.current.play();
          console.log("Audio started playing successfully");
        }
        setIsPlaying(!isPlaying);
      } catch (error) {
        console.error("Error playing audio:", error);
        setIsLoading(false);
      }
    }
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
    setIsMuted(newVolume === 0);
  };

  const toggleMute = () => {
    if (audioRef.current) {
      const newMutedState = !isMuted;
      setIsMuted(newMutedState);
      audioRef.current.volume = newMutedState ? 0 : volume;
    }
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gradient-to-t from-hover-foam to-hover-foam/95 backdrop-blur-lg border-t border-hover-coffee/10 p-4 animate-slide-up z-50">
      <audio
        ref={audioRef}
        onError={(e) => {
          console.error("Audio error:", e);
          setIsLoading(false);
        }}
        onPlay={() => {
          console.log("Audio started playing");
          setIsLoading(false);
        }}
        onPause={() => {
          console.log("Audio paused");
          setIsLoading(false);
        }}
        onLoadStart={() => {
          console.log("Audio loading started");
          setIsLoading(true);
        }}
        onCanPlay={() => {
          console.log("Audio can play");
          setIsLoading(false);
        }}
        onStalled={() => {
          console.log("Audio stalled");
          setIsLoading(true);
        }}
        onWaiting={() => {
          console.log("Audio waiting");
          setIsLoading(true);
        }}
      />
      
      <div className="max-w-5xl mx-auto flex items-center justify-between gap-4 px-4">
        <div className="flex items-center gap-6 flex-1">
          <button
            onClick={togglePlay}
            disabled={isLoading}
            className="w-14 h-14 rounded-full bg-hover-coffee text-hover-foam flex items-center justify-center transition-all hover:scale-105 active:scale-95 shadow-lg hover:shadow-xl hover:bg-hover-espresso disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isPlaying ? (
              <Pause className="w-6 h-6" />
            ) : (
              <Play className="w-6 h-6 ml-1" />
            )}
          </button>
          <div className="text-hover-espresso min-w-[140px]">
            <p className="text-sm font-medium opacity-60">Now Playing</p>
            <h3 className="font-semibold text-lg">The Bean Stream</h3>
          </div>
        </div>

        <div className="relative flex-1 h-12 overflow-hidden">
          <div className="flex whitespace-nowrap animate-wave">
            <div className="flex items-center gap-4 px-8">
              <div className="relative">
                <Radio className="w-8 h-8 text-hover-espresso animate-pulse" />
                <div className="absolute -inset-2 border-2 border-hover-coffee/40 rounded-full animate-ping" />
                <div className="absolute -inset-4 border-2 border-hover-coffee/30 rounded-full animate-ping [animation-delay:150ms]" />
                <div className="absolute -inset-6 border-2 border-hover-coffee/20 rounded-full animate-ping [animation-delay:300ms]" />
              </div>
              
              <svg className="h-32 w-[1200px]" viewBox="0 0 1200 100">
                <path
                  d="M0,50 C100,50 200,30 300,50 C400,70 500,30 600,50 C700,70 800,30 900,50 C1000,70 1100,30 1200,50"
                  fill="none"
                  stroke="rgba(139, 115, 85, 0.2)"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>

              <div className="relative">
                <Radio className="w-8 h-8 text-hover-espresso animate-pulse [animation-delay:200ms]" />
                <div className="absolute -inset-2 border-2 border-hover-coffee/40 rounded-full animate-ping [animation-delay:200ms]" />
                <div className="absolute -inset-4 border-2 border-hover-coffee/30 rounded-full animate-ping [animation-delay:350ms]" />
                <div className="absolute -inset-6 border-2 border-hover-coffee/20 rounded-full animate-ping [animation-delay:500ms]" />
              </div>
            </div>
          </div>
        </div>

        {!isMobile && (
          <div className="flex items-center gap-4 flex-1 justify-end">
            <button
              onClick={toggleMute}
              className="text-hover-coffee hover:text-hover-espresso transition-colors"
            >
              {isMuted ? (
                <VolumeX className="w-6 h-6" />
              ) : (
                <Volume2 className="w-6 h-6" />
              )}
            </button>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={isMuted ? 0 : volume}
              onChange={handleVolumeChange}
              className="w-24 h-2 appearance-none bg-hover-espresso/20 rounded-full overflow-hidden cursor-pointer hover:bg-hover-espresso/30 transition-colors"
              style={{
                backgroundImage: `linear-gradient(90deg, #8B7355 ${
                  (isMuted ? 0 : volume) * 100
                }%, transparent ${(isMuted ? 0 : volume) * 100}%)`,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AudioPlayer;
