import { Link, useLocation } from "react-router-dom";
import { Building2, Palette, Megaphone, Calendar, Users } from "lucide-react";
import { Button } from "@/components/ui/button";

const links = [
  { to: "/business", label: "Business", icon: Building2 },
  { to: "/creator", label: "Creator", icon: Palette },
  { to: "/marketer", label: "Marketer", icon: Megaphone },
  { to: "/live-events", label: "Events", icon: Calendar },
  { to: "/communities", label: "Communities", icon: Users },
];

interface NavLinksProps {
  className?: string;
}

const NavLinks = ({ className = "" }: NavLinksProps) => {
  const location = useLocation();
  
  return (
    <div className={`flex flex-col md:flex-row gap-2 ${className}`}>
      {links.map(({ to, label, icon: Icon }) => (
        <Link key={to} to={to}>
          <Button
            variant="ghost"
            className={`flex items-center gap-2 w-full justify-start ${
              location.pathname === to ? "bg-hover-coffee/10 text-hover-coffee" : ""
            }`}
          >
            <Icon className="h-4 w-4" />
            {label}
          </Button>
        </Link>
      ))}
    </div>
  );
};

export default NavLinks;