import { Link } from "react-router-dom";
import { Menu } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import NavLinks from "./NavLinks";
import AuthButton from "./AuthButton";

interface MobileMenuProps {
  session: any;
}

const MobileMenu = ({ session }: MobileMenuProps) => {
  return (
    <Sheet>
      <SheetTrigger asChild className="md:hidden">
        <Button variant="ghost" size="icon">
          <Menu className="h-6 w-6" />
        </Button>
      </SheetTrigger>
      <SheetContent>
        <div className="flex flex-col gap-4 mt-8">
          <div className="md:hidden">
            <NavLinks />
          </div>
          {session && (
            <>
              <Link to="/profile">
                <Button variant="ghost" className="w-full justify-start">Profile</Button>
              </Link>
              <Link to="/my-space">
                <Button variant="ghost" className="w-full justify-start">My Space</Button>
              </Link>
              <Link to="/settings">
                <Button variant="ghost" className="w-full justify-start">Settings</Button>
              </Link>
            </>
          )}
          <AuthButton />
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default MobileMenu;