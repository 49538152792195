import NavLinks from "./NavLinks";
import AuthButton from "./AuthButton";
import SettingsDropdown from "./SettingsDropdown";

interface DesktopMenuProps {
  session: any;
}

const DesktopMenu = ({ session }: DesktopMenuProps) => {
  return (
    <div className="hidden md:flex items-center gap-4">
      <NavLinks />
      {session && (
        <>
          <SettingsDropdown />
        </>
      )}
      <AuthButton />
    </div>
  );
};

export default DesktopMenu;