import { Carousel, CarouselContent, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import EventCard from "./EventCard";
import type { EmblaOptionsType } from 'embla-carousel';
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";

const Events = () => {
  const { data: events = [], isLoading, error } = useQuery({
    queryKey: ["events"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("events")
        .select("*")
        .order("date", { ascending: true });

      if (error) {
        console.error("Error fetching events:", error);
        throw error;
      }

      return data || []; // Ensure we always return an array
    },
  });

  const options: EmblaOptionsType = {
    align: "start",
    loop: true,
    slidesToScroll: 1,
    dragFree: true
  };

  if (isLoading) {
    return (
      <section className="mt-24 space-y-8">
        <div className="text-center space-y-6 mb-16">
          <h2 className="text-4xl font-bold text-hover-espresso">
            Loading Events...
          </h2>
        </div>
      </section>
    );
  }

  if (error) {
    console.error("Error loading events:", error);
    return (
      <section className="mt-24 space-y-8">
        <div className="text-center space-y-6 mb-16">
          <h2 className="text-4xl font-bold text-hover-espresso">
            Unable to load events
          </h2>
        </div>
      </section>
    );
  }

  // Add console.log to help debug the events data
  console.log("Events data:", events);

  return (
    <section className="mt-24 space-y-8">
      <div className="mt-12 relative px-4 md:px-8 lg:px-12">
        {Array.isArray(events) && events.length > 0 ? (
          <Carousel
            opts={options}
            className="w-full"
          >
            <CarouselContent className="-ml-2 md:-ml-4">
              {events.map((event) => (
                <div key={event.id} className="pl-2 md:pl-4 basis-full md:basis-1/2 lg:basis-1/3">
                  <EventCard event={event} />
                </div>
              ))}
            </CarouselContent>
            <CarouselPrevious className="absolute left-0 md:-left-8 lg:-left-12" />
            <CarouselNext className="absolute right-0 md:-right-8 lg:-right-12" />
          </Carousel>
        ) : (
          <div className="text-center py-8 text-hover-coffee">
            No events scheduled at the moment
          </div>
        )}
      </div>
    </section>
  );
};

export default Events;