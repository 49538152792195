import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useUserData } from "@/hooks/useUserData";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Badge } from "@/components/ui/badge";
import { X } from "lucide-react";

interface BusinessRequirementFormProps {
  onSuccess?: () => void;
}

export const BusinessRequirementForm = ({ onSuccess }: BusinessRequirementFormProps) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedIndustries, setSelectedIndustries] = useState<number[]>([]);
  const [industries, setIndustries] = useState<{ id: number; name: string; }[]>([]);
  const [loading, setLoading] = useState(false);
  const { currentUserId } = useUserData();
  const { toast } = useToast();

  useState(() => {
    fetchIndustries();
  });

  const fetchIndustries = async () => {
    const { data, error } = await supabase
      .from('industries')
      .select('*')
      .order('name');
    
    if (error) {
      console.error('Error fetching industries:', error);
      return;
    }

    setIndustries(data || []);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUserId) {
      toast({
        title: "Error",
        description: "You must be logged in to create a business requirement",
        variant: "destructive"
      });
      return;
    }

    setLoading(true);
    try {
      // Insert the business requirement
      const { data: requirement, error: requirementError } = await supabase
        .from('business_requirements')
        .insert([
          { title, description, user_id: currentUserId }
        ])
        .select()
        .single();

      if (requirementError) throw requirementError;

      // Insert the industry associations
      if (selectedIndustries.length > 0) {
        const { error: industriesError } = await supabase
          .from('business_requirement_industries')
          .insert(
            selectedIndustries.map(industryId => ({
              requirement_id: requirement.id,
              industry_id: industryId
            }))
          );

        if (industriesError) throw industriesError;
      }

      toast({
        title: "Success",
        description: "Business requirement created successfully"
      });

      // Reset form
      setTitle("");
      setDescription("");
      setSelectedIndustries([]);
      
      // Call onSuccess callback if provided
      onSuccess?.();
    } catch (error) {
      console.error('Error creating business requirement:', error);
      toast({
        title: "Error",
        description: "Failed to create business requirement",
        variant: "destructive"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleIndustrySelect = (industryId: string) => {
    const id = parseInt(industryId);
    if (!selectedIndustries.includes(id)) {
      setSelectedIndustries([...selectedIndustries, id]);
    }
  };

  const removeIndustry = (industryId: number) => {
    setSelectedIndustries(selectedIndustries.filter(id => id !== industryId));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="title">Title</Label>
        <Input
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter the title of your business requirement"
          required
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="description">Description</Label>
        <Textarea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Describe your business requirement in detail"
          required
          rows={4}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="industries">Industries</Label>
        <Select onValueChange={handleIndustrySelect}>
          <SelectTrigger id="industries">
            <SelectValue placeholder="Select industries" />
          </SelectTrigger>
          <SelectContent>
            {industries.map((industry) => (
              <SelectItem
                key={industry.id}
                value={industry.id.toString()}
                disabled={selectedIndustries.includes(industry.id)}
              >
                {industry.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <div className="flex flex-wrap gap-2 mt-2">
          {selectedIndustries.map((industryId) => {
            const industry = industries.find(i => i.id === industryId);
            if (!industry) return null;
            return (
              <Badge
                key={industry.id}
                variant="secondary"
                className="flex items-center gap-1"
              >
                {industry.name}
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  className="h-4 w-4 p-0 hover:bg-transparent"
                  onClick={() => removeIndustry(industry.id)}
                >
                  <X className="h-3 w-3" />
                </Button>
              </Badge>
            );
          })}
        </div>
      </div>

      <Button type="submit" disabled={loading} className="w-full">
        {loading ? "Creating..." : "Create Business Requirement"}
      </Button>
    </form>
  );
};